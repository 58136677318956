<template>
  <div class="gemini-container">
    <div v-if="response">
      <h3
        v-for="item in response.problemList"
        @click="amazonAffiliateLink(`${state.selectedCar} ${item}`)"
        :key="item"
      >
        {{ item }}
      </h3>
    </div>
    <div v-if="error">
      <p>Error: {{ error }}</p>
    </div>
  </div>
</template>
  

<script>
import axios from "axios";
import { state } from "../state";

export default {
  name: "GemeniApi",
  data() {
    return {
      response: null,
      error: null,
    };
  },
  setup() {
    return { state };
  },
  watch: {
    "state.selectedCar": {
      handler: "callGemini",
      immediate: true,
    },
  },
  methods: {
    async callGemini() {
      const prompt = state.selectedCar;
      try {
        const response = await axios.post(
          "https://bestcarfixes.com/api/gemini/car",
          {
            prompt,
          }
        );
        this.response = response.data;
      } catch (error) {
        this.error = "backend error";
      }
    },
    amazonAffiliateLink(searchTerm) {
      this.baseUrl = "https://www.amazon.com/s";
      this.searchTerm = encodeURIComponent(
        searchTerm.trim().replace(/\s+/g, "+")
      );
      this.affiliateTag = "bestcarfixes-20";
      const link = `${this.baseUrl}?k=${this.searchTerm}&tag=${this.affiliateTag}`;
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped >
</style>