import { createRouter, createWebHistory } from 'vue-router';

// Import your components
import Home from '@/views/HomeView.vue';
import About from '@/views/AboutView.vue';
import Contact from '@/views/ContactView.vue';

// Define routes
const routes = [
  { path: '/', name: 'HomeView', component: Home },
  { path: '/about', name: 'AboutView', component: About },
  { path: '/contact', name: 'ContactView', component: Contact },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;