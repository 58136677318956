<template>
  <div id="images">
    <div v-if="response">
      <p>{{ response.kind }}</p>
      <img
        v-for="item in response.imageArrayResponse"
        :key="item"
        :src="item"
      />
    </div>
    <div v-if="error">
      <p>Error: {{ error }}</p>
    </div>
  </div>
</template>
    
  
  <script>
import axios from "axios";
import { state } from "../state";

export default {
  name: "ImageSearch",
  data() {
    return {
      response: null,
      error: null,
    };
  },
  setup() {
    return { state };
  },
  watch: {
    "state.selectedCar": {
      handler: "callImageSearch",
      immediate: true,
    },
  },
  methods: {
    async callImageSearch() {
      const query = state.selectedCar;
      try {
        const response = await axios.post(
          "https://bestcarfixes.com/api/search/image",
          {
            query,
          }
        );
        this.response = response.data;
      } catch (error) {
        this.error = "backend error";
      }
    },
  },
};
</script>
  
  <style scoped >
img {
  width: 80%;
  padding: 20px;
  border: solid 2px black;
  border-radius: 20px;
  margin-bottom: 40px;
}
@media (max-width: 580px) {
  #images img {
    margin-bottom: 0px;
  }
}
</style>