<template>
  <h1 class="funky-text-two">Repair your car</h1>
  <h2>
    Enter your vehicle below to see common issues and get free access to our
    repair library!
  </h2>
  <br />
  <div class="car-selector" @click="state.isSelected = false">
    <div class="card">
      <v-select
        v-model="selectedMake"
        :options="makes"
        placeholder="Make"
        :clearable="false"
        @click="resetModelAndYear"
      />
      <img v-if="selectedMake" :src="makeIcon" width="100px" />
    </div>
    <div class="card">
      <v-select
        v-model="selectedModel"
        :options="models"
        :disabled="!selectedMake"
        placeholder="Model"
        @click="resetYear"
        :clearable="false"
      />
      <img v-if="selectedModel" :src="modelIcon" width="100px" />
    </div>
    <div class="card">
      <v-select
        v-model="selectedYear"
        :options="years"
        :disabled="!selectedModel"
        placeholder="Year"
        :clearable="false"
      />
      <div v-if="selectedYear" id="year-container">
        <h1>{{ selectedYear }}</h1>
      </div>
    </div>
  </div>
  <button v-if="!state.isSelected" class="funky-text" @click="handleSelection">
    Go!
  </button>
  <br />
  <a id="reset" @click="handleReset">Reset</a>
</template>
  
  <script>
import carData from "@/assets/cars.json";
import vSelect from "vue-select";
import { state } from "../state";

export default {
  name: "CarSelector",
  components: { vSelect },
  data() {
    return {
      carData,
      selectedMake: "",
      selectedModel: "",
      selectedYear: "",
    };
  },
  setup() {
    return { state };
  },
  computed: {
    makes() {
      return [...new Set(this.carData.map((car) => car.make))].sort();
    },
    makeIcon() {
      try {
        const makeName = this.selectedMake
          ? this.selectedMake.toLocaleLowerCase().replace(/\s+/g, "-")
          : "default";
        return require(`../assets/caricons/${makeName}.svg`);
      } catch (error) {
        console.warn(
          `Icon for make '${this.selectedMake}' not found, using default icon.`
        );
        return require("../assets/caricons/default.svg");
      }
    },
    models() {
      if (!this.selectedMake) return [];
      return [
        ...new Set(
          this.carData
            .filter((car) => car.make === this.selectedMake)
            .map((car) => car.model)
        ),
      ].sort();
    },
    modelIcon() {
      if (!this.selectedMake || !this.selectedModel)
        return require("../assets/bodytypes/default.jpg");

      const filteredCars = this.carData.filter(
        (car) =>
          car.make === this.selectedMake && car.model === this.selectedModel
      );

      console.log("Filtered Cars for Model Icon:", filteredCars);

      const latestCar = filteredCars.sort((a, b) => b.year - a.year)[0];
      console.log(latestCar.Body);

      if (!latestCar || !latestCar.Body) {
        console.warn("No body type found for the selected model.");
        return require("../assets/bodytypes/default.jpg");
      }

      const bodyTypeName = latestCar.Body.toLocaleLowerCase().replace(
        /\s+/g,
        "-"
      );

      console.log("Body Type Name:", bodyTypeName);

      try {
        return require(`../assets/bodytypes/${bodyTypeName}.png`);
      } catch (error) {
        console.warn(
          `Image for body type '${bodyTypeName}' not found, using default.`
        );
        return require("../assets/bodytypes/default.jpg");
      }
    },
    years() {
      if (!this.selectedModel) return [];
      return [
        ...new Set(
          this.carData
            .filter(
              (car) =>
                car.make === this.selectedMake &&
                car.model === this.selectedModel
            )
            .map((car) => car.year)
        ),
      ].sort((a, b) => b - a); // Sort years descending
    },
  },
  methods: {
    resetModelAndYear() {
      this.selectedModel = "";
      this.selectedYear = "";
      console.log("resetModelYear");
    },
    resetYear() {
      this.selectedYear = "";
      console.log("resetYear");
    },
    handleSelection() {
      // Concatenate selected make, model, and year into a single string
      const selectedCar = `${this.selectedMake} ${this.selectedModel} ${this.selectedYear}`;
      // Emit the selectedCar string to the parent component
      this.$emit("car-selected", selectedCar);
      console.log(selectedCar);
    },
    handleReset() {
      this.selectedMake = "";
      this.selectedModel = "";
      this.selectedYear = "";
      state.isSelected = false;
    },
  },
};
</script>
  

<style>
.car-selector {
  display: flex;
  justify-content: space-around;
}

#year-container {
  height: 100px;
}
#year-container h1 {
  margin: 0;
  padding-top: 40px;
}

.v-select {
  width: auto !important;
  border: none !important;
  margin: 0;
}

.card {
  width: 30%; /* Adjust width as needed */
  padding-top: 20px;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: -5px 5px 0px #f2a541, -10px 10px 0px black;
}

button {
  padding: 20px;
  background-color: #f2a541;
  color: white;
  font-size: 50px;
  border: solid 5px black;
  border-radius: 20px;
  cursor: pointer;
  margin: 40px;
}
#reset {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

li:hover {
  background-color: #f2a541;
  border-radius: 5px;
}

.v-select {
  width: 100%; /* Ensure full width within the card */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.v-select__single-value {
  color: #333;
}

.v-select__dropdown-container {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.v-select__option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.v-select__option:hover {
  background-color: #f0f0f0;
}

.vs__search {
  width: 90%;
  text-align: center;
  border: none;
  border-bottom: 2px solid #8b8b8b;
  border-radius: 0;
  font-size: 20px;
  font-weight: 800;
  color: #000000;
  background-color: transparent;
  transition: border-color 0.3s ease-in-out;
}

.vs__search:focus {
  outline: none;
  border-bottom-color: #007bff;
}
.vs__actions {
  display: none;
}

.vs__selected {
  font-size: 20px;
  font-weight: 800;
}
</style>
  