<template>
  <nav>
    <div>
      <img id="logo" src="./assets/BestCarFixesLogoHD.png" />
    </div>
  </nav>
  <div id="contact-bar">
    <div class="contact" @click="callPhone('3472281875')">
      <font-awesome-icon :icon="['fas', 'phone']" class="fa-2x icon-black" />
      <h3>(347) 228-1875</h3>
    </div>
    <div
      class="contact"
      @click="openLink('https://maps.app.goo.gl/CJQnn9YP8tUhK2596')"
    >
      <font-awesome-icon
        :icon="['fas', 'location-dot']"
        class="fa-2x icon-black"
      />
      <h3>1287 Utica Ave</h3>
    </div>
  </div>
  <div class="menu-spacer">
    <div class="menu-bar">
      <div class="menu-text">
        <router-link style="color: black" to="/">Home</router-link>
        <router-link style="color: black" to="/about">About</router-link>
        <router-link style="color: black" to="/contact">Contact</router-link>
        <!-- Add more navigation links as needed -->
      </div>
    </div>
  </div>

  <!-- Router View -->
  <router-view></router-view>

  <footer>
    <p>Website by Noah Ballinger</p>
    <a href="https://noahballinger.com">noahballinger.com</a>
  </footer>
</template>

<script>
export default {
  name: "App",
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    callPhone(number) {
      window.open(`tel:+1${number}`);
    },
  },
};
</script>



<style>
@font-face {
  font-family: "Bebas";
  src: url("@/assets/fonts/BebasNeue-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Bebas", Avenir, Helvetica, Arial, sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

body {
  margin: 0;
}

nav {
  position: relative; /* Ensure the logo's absolute positioning is relative to the nav */
  height: 150px;
  background-color: #f2a541;
  border-top: 2px solid black;
}

#logo {
  position: absolute;
  left: 50%; /* Position the logo from the center of the nav */
  transform: translateX(-50%); /* Move it back by half its width to center it */
  width: calc(10vw + 250px); /* Set the width of the logo directly */
  top: 0px; /* Adjust this value to control how much the logo hangs onto the page */
  pointer-events: none;
}
#contact-bar {
  display: flex;
  padding-top: 20px;
  border: solid 2px black;
  background-color: white;
}

.contact {
  text-align: center;
  margin: auto;
  margin-bottom: 0px;
}

.icon-black {
  color: black;
}

.icon-red {
  color: red;
}

.black-background {
  background-color: black;
}

.menu-spacer {
  background-color: #f2a541;
  padding-top: 20px;
  border-bottom: solid 10px black;
}

.menu-bar {
  background-color: white;
  border-top: solid 10px black;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-justify-container {
  display: flex;
  justify-content: center;
}

.half {
  width: 50%;
  display: inline-block;
}

.match-height {
  display: flex;
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  text-align: center; /* Center the text inside the div */
}

.purple-block {
  background-color: #946ca8;
  border-bottom: 10px solid black;
  padding: 20px;
}

.green-block {
  background-color: #6da86c;
  border-bottom: 10px solid black;
  padding: 20px;
}

.blue-block {
  background-color: #42a4f5;
  border-bottom: 10px solid black;
  padding: 20px;
}

.funky-text {
  font-size: 50px;
  font-weight: 1000;
  color: white; /* Main text color */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000, -6px 6px 0 black;

  text-transform: uppercase; /* Make the text all caps */
  letter-spacing: 1px; /* Optional: Adjust spacing between letters */
}

.funky-text-two {
  font-size: 50px;
  font-weight: 1000;
  color: white; /* Main text color */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000, -3px 3px 0 #f2a541, -6px 6px 0 black;

  text-transform: uppercase; /* Make the text all caps */
  letter-spacing: 1px; /* Optional: Adjust spacing between letters */
}

.menu-text {
  font-size: 30px;
  font-weight: 200px;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
}

.router-link-active {
  font-weight: 1000px;
}

#repair-library-title {
  padding: 5%;
  background-color: #f2a541;
  border: solid 10px black;
  border-top: none;
  display: flex;
  align-items: center;
}

#youtube-link {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#car-selector {
  padding: 5vh;
  width: 50%;
  margin: auto;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#photogrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  padding: 10px;
  background-color: black;
  width: 100%;
}
#photogrid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-box-shadow: inset 0px 0px 0px 10px #f00;
  -moz-box-shadow: inset 0px 0px 0px 10px #f00;
  box-shadow: inset 0px 0px 0px 10px #f00;
}
#photogrid img:nth-child(1) {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
#photogrid img:nth-child(2) {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}
#photogrid img:nth-child(3) {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.car-info-window {
  margin: 0 2% 0 2%;
  padding-top: 20px;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: -5px 5px 0px #f2a541, -10px 10px 0px black;
  height: auto;
  background-color: white;
}

.gradient-background {
  background: linear-gradient(
    0deg,
    rgb(255, 203, 136) 0%,
    rgb(255, 215, 162) 100%
  );
  padding-top: 50px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  padding-bottom: 50px;
  border: 2px solid black;

  border-bottom: solid 5px black;
}

footer {
  background-color: #f2a541;
  padding: 20px;
  border-top: solid 5px black;
  margin-top: 5%;
}
.secondary-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background-color: #f2a541;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #9b6b2c;
}
@media (max-width: 1900px) {
  .menu-spacer {
    background-color: #f2a541;
    padding-top: 60px;
    border-bottom: solid 10px black;
  }
}
@media (max-width: 1500px) {
  .menu-spacer {
    background-color: #f2a541;
    padding-top: 40px;
    border-bottom: solid 10px black;
  }
}
@media (max-width: 580px) {
  .menu-spacer {
    background-color: #f2a541;
    padding-top: 0px;

    border-bottom: none;
  }
  body #contact-bar {
    padding-top: 40px;
  }
  body #logo {
    position: absolute;
    pointer-events: none;
    left: 50%; /* Position the logo from the center of the nav */
    transform: translateX(
      -50%
    ); /* Move it back by half its width to center it */
    width: calc(8vw + 250px); /* Set the width of the logo directly */
    top: -10px; /* Adjust this value to control how much the logo hangs onto the page */
  }
  body .green-block {
    border-top: none;
  }
  body .funky-text {
    font-size: 40px;
    font-weight: 1000;
    color: white; /* Main text color */
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000, -6px 6px 0 black;

    text-transform: uppercase; /* Make the text all caps */
    letter-spacing: 1px; /* Optional: Adjust spacing between letters */
  }

  body .funky-text-two {
    font-size: 40px;
    font-weight: 1000;
    color: white; /* Main text color */
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000, -3px 3px 0 #f2a541, -6px 6px 0 black;

    text-transform: uppercase; /* Make the text all caps */
    letter-spacing: 1px; /* Optional: Adjust spacing between letters */
  }
  .menu-text {
    font-size: 20px;
  }
  h2 {
    font-size: 20px;
  }
  body .half {
    width: 100%;
  }
  body .flex-container {
    display: block;
  }
  body #car-selector {
    width: 80%;
    margin: auto;
  }
  body #repair-library-title {
    display: block;
    border-left: none;
    border-right: none;
  }
  body #youtube-link {
    width: 80%;
  }
  body .car-info-window {
    margin: 20px;
    padding-top: 10px;
    width: 80%;
    border: 2px solid black;
    border-radius: 20px;
    box-shadow: -5px 5px 0px #f2a541, -10px 10px 0px black;
    height: auto;
  }
  body .car-info-window h3 {
    margin: 0;
  }
}
</style>
