<template>
  <div class="flex-container">
    <div class="half black-background">
      <div class="container">
        <div id="photogrid" ref="photogrid">
          <img src="../assets/BCF-1.jpg" />
          <img src="../assets/BCF-2.webp" />
          <img src="../assets/WithCar.webp" />
        </div>
      </div>
    </div>
    <div class="half">
      <div class="green-block match-height">
        <h1 class="funky-text">Family Owned</h1>
      </div>
      <div class="purple-block match-height">
        <h2 class="funky-text">30+ Years Experience</h2>
      </div>
      <div class="blue-block match-height">
        <h1 class="funky-text">Reliable Service</h1>
      </div>
    </div>
  </div>

  <div id="repair-library-title">
    <div>
      <h2>
        Custom automotive electronics, computer programming, key programming,
        electrical work, and advanced diagnostics.
      </h2>
    </div>

    <img
      id="youtube-link"
      @click="openLink('https://www.youtube.com/bestcarfixes')"
      src="../assets/youtubelink.png"
    />
  </div>
  <div id="car-selector">
    <CarSelector @car-selected="handleCarSelected" />
  </div>
  <div v-if="state.isSelected"></div>

  <div v-if="state.isSelected" class="flex-container gradient-background">
    <div class="half car-info-window">
      <ImageSearch />
      <div>
        <h1 class="funky-text">Top 10 Issues</h1>
        <h2>Click an issue below to find parts:</h2>
        <br />
        <Gemeni />
      </div>
      <br />
      <button
        @click="amazonAffiliateLink(`${state.selectedCar} parts`)"
        class="secondary-button"
      >
        Shop Parts >
      </button>
    </div>

    <div class="car-info-window half">
      <h1 class="funky-text">Repair Library</h1>
      <h2>We've uploaded thousands of videos on common issues.</h2>
      <YouTubeSearch />
    </div>
  </div>
</template>
  
  <script>
import { state } from "../state.js";
import YouTubeSearch from "../components/YouTubeSearch.vue";
import CarSelector from "../components/CarSelector.vue";
import Gemeni from "../components/Gemeni.vue";
import ImageSearch from "../components/ImageSearch.vue";

export default {
  name: "HomeView",
  components: { YouTubeSearch, CarSelector, Gemeni, ImageSearch },
  setup() {
    return { state };
  },
  mounted() {
    const images = this.$refs.photogrid.querySelectorAll("img");
    let loadedCount = 0;

    images.forEach((image) => {
      image.addEventListener("load", () => {
        loadedCount++;
        if (loadedCount === images.length) {
          this.setBlockHeight();
        }
      });
    });

    window.addEventListener("resize", this.setBlockHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setBlockHeight);
  },
  methods: {
    handleCarSelected(carInfo) {
      state.selectedCar = carInfo;
      state.isSelected = true;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    amazonAffiliateLink(searchTerm) {
      this.baseUrl = "https://www.amazon.com/s";
      this.searchTerm = encodeURIComponent(
        searchTerm.trim().replace(/\s+/g, "+")
      );
      this.affiliateTag = "bestcarfixes-20";
      const link = `${this.baseUrl}?k=${this.searchTerm}&tag=${this.affiliateTag}`;
      window.open(link, "_blank");
    },
    setBlockHeight() {
      const photoGridHeight = this.$refs.photogrid.offsetHeight;
      const blocks = document.querySelectorAll(".match-height");
      const numberOfBlocks = blocks.length;
      const blockHeight =
        (photoGridHeight - numberOfBlocks * 50) / numberOfBlocks;

      blocks.forEach((block) => {
        block.style.height = `${blockHeight}px`;
      });
    },
  },
};
</script>