<template>
  <div>
    <h1 class="funky-text">Contact</h1>
    <section>
      <h2>Call</h2>
      <p>(347) 228-1875</p>
    </section>
    <section>
      <h2>Email</h2>
      <p>idrissharoun@gmail.com</p>
    </section>
    <section>
      <h2>Location</h2>
      <p>We're located in Brooklyn, at 1287 Utica Ave.</p>
    </section>
    <button
      class="secondary-button"
      @click="openLink('https://maps.app.goo.gl/CJQnn9YP8tUhK2596')"
    >
      Get Directions
    </button>
    <section class="flex-justify-container">
      <div
        style="
          width: 100%;
          height: 400px;
          margin-top: 1rem;
          width: 80%;
          border: solid 10px black;
          border-radius: 10px;
        "
      >
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=-73.93100857734682%2C40.64059206200634%2C-73.92700707912447%2C40.64159193796814&layer=mapnik&marker=40.641092%2C-73.929008"
          style="border: 1px solid black"
          allowfullscreen
        ></iframe>
        <br />
        <small> </small>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "ContactView",
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
  
  <style scoped>
section {
  margin-bottom: 2rem;
}
</style>
  