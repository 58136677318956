<template>
  <div class="flex-justify-container">
    <div class="story">
      <div class="section">
        <img class="float-left" src="../assets/WithCar.webp" alt="With Car" />
        <div>
          <h1 class="funky-text">About Us</h1>
          <p>
            Best Car Fixes is a YouTube channel, repair service, and educational
            resource that aims to bring quality automotive repair to everyone.
            <br /><br />It was launched by Mohamed Hadroun Idriss, an expert
            mechanic based in Brooklyn with over 30 years of experience.
          </p>
          <img
            id="youtube-link"
            @click="openLink('https://www.youtube.com/bestcarfixes')"
            src="../assets/youtubelink.png"
            alt="YouTube Link"
          />
        </div>
      </div>
      <div class="section">
        <img class="float-right" src="../assets/About-1.webp" alt="About Us" />
        <p>
          Visit our YouTube Channel to discover a huge library of Do-It-Yourself
          car repair videos, or bring your car in to our shop for expert
          service!
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "AboutView",
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
  
  <style scoped>
.story {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.section {
  margin-bottom: 20px;
  overflow: hidden; /* Clear floats */
}

.space-vertically {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img {
  width: 40%;
  margin: 20px;
  cursor: pointer;
  border-radius: 20px;
}

.float-left {
  float: left;
  margin-right: 20px;
}

.float-right {
  float: right;
  margin-left: 20px;
}

p {
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 760px) {
  .section {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    text-align: center;
  }

  img {
    width: 100%; /* Make images take the full width */
    margin: 0 auto 20px; /* Center images and add space below */
  }

  .float-left,
  .float-right {
    float: none; /* Disable float for smaller screens */
    margin: 0;
  }
}
</style>
  